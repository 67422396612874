import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import '../src/styles/main.css';
import Loader from './components/Loader';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import App from './components/App';

ReactDOM.render(
	<BrowserRouter>
		<Suspense fallback={<Loader />}>
			<ScrollToTop />
			<App />
		</Suspense>
	</BrowserRouter>,
	document.getElementById('root')
);
