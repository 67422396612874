import React, { lazy } from 'react';
import { Route, useLocation, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Home = lazy(() => import('../pages/Home'));
const About = lazy(() => import('../pages/About'));
const BrandIdentity = lazy(() => import('../pages/BrandIdentity'));
const Careers = lazy(() => import('../pages/Careers'));
const Contact = lazy(() => import('../pages/Contact'));
const EventManagement = lazy(() => import('../pages/EventManagement'));
const GraphicDesign = lazy(() => import('../pages/GraphicDesign'));
const PhotographyAndVideo = lazy(() => import('../pages/PhotographyAndVideo'));
const Portfolio = lazy(() => import('../pages/Portfolio'));
const SEO = lazy(() => import('../pages/SEO'));
const SMM = lazy(() => import('../pages/SMM'));
const ThankYou = lazy(() => import('../pages/ThankYou'));
const VirtualTour = lazy(() => import('../pages/VirtualTour'));
const WebDevelopment = lazy(() => import('../pages/WebDevelopment'));

const App = () => {
	let location = useLocation();
	// console.log(location);

	const routes = [
		{ path: '/', Component: Home },
		{ path: '/portfolio', Component: Portfolio },
		{ path: '/360-virtual-tour', Component: VirtualTour },
		{ path: '/social-media-marketing', Component: SMM },
		{ path: '/web-development', Component: WebDevelopment },
		{ path: '/graphic-design', Component: GraphicDesign },
		{ path: '/brand-identity', Component: BrandIdentity },
		{ path: '/seo', Component: SEO },
		{ path: '/event-management', Component: EventManagement },
		{
			path: '/photography-and-video-production',
			Component: PhotographyAndVideo,
		},
		{ path: '/about', Component: About },
		{ path: '/contact', Component: Contact },
		{ path: '/careers', Component: Careers },
		{ path: '/thank-you', Component: ThankYou },
	];

	return (
		<TransitionGroup>
			<CSSTransition key={location.key} classNames='slide' timeout={800} appear>
				<Switch location={location}>
					{routes.map(({ path, Component }) => {
						return (
							<Route exact path={path} key={path}>
								<div className='slide'>
									<Component />
								</div>
							</Route>
						);
					})}
				</Switch>
			</CSSTransition>
		</TransitionGroup>
	);
};

export default App;
